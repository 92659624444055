import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchOwn(data) {
        return axios.get(`${endpoint}/own`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAccessible(data) {
        return axios.get(`${endpoint}/accessible`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function unlock(id, data) {
        return axios.get(`${endpoint}/${id}/unlock`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function close(id) {
        return axios.post(`${endpoint}/${id}/close`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function step(id, data) {
        return axios.get(`${endpoint}/${id}/step`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function status(id, data) {
        return axios.get(`${endpoint}/${id}/status`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function transfer(id, data) {
        return axios.post(`${endpoint}/${id}/transfer`, data).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function history(id) {
        return axios.get(`${endpoint}/${id}/history`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function balance(id) {
        return axios.get(`${endpoint}/${id}/balance`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        fetchAccessible,
        step,
        status,
        unlock,
        transfer,
        history,
        balance,
        close
    }
}

export default service
