import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchOwn(data) {
        return axios.get(`${endpoint}/own`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAccessible(data) {
        return axios.get(`${endpoint}/accessible`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function realize(id) {
        return axios.post(`${endpoint}/${id}/realize`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        fetchAccessible,
        realize
    }
}

export default service
