import axios from './axios'
import qs from "qs";

function service(endpoint) {

    function fetchOwn(data) {
        return axios.get(endpoint, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAccessible(data) {
        return axios.get(`contracts/accessible`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function sent(id) {
        return axios.post(`contracts/${id}/sent`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function rejected(id) {
        return axios.post(`contracts/${id}/rejected`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function canceled(id) {
        return axios.post(`contracts/${id}/canceled`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function signed(id) {
        return axios.post(`contracts/${id}/signed`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function closed(id) {
        return axios.post(`contracts/${id}/closed`).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchOwn,
        fetchAccessible,
        sent,
        rejected,
        canceled,
        signed,
        closed
    }
}

export default service
