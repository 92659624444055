import { createStore } from 'vuex'
import modules from './modules'
import { crud, sortable, searchable, autocomplete, accessible } from "./generator";
const _ = require("lodash");

const crudModules = crud({
  user: 'users',
  menu: 'menus',
  menuPoint: 'menuPoints',
  menuPointCategory: 'menuPointCategories',
  address: 'addresses',
  addressType: 'addressTypes',
  country: 'countries',
  position: 'positions',
  contact: 'contacts',
  companySize: 'companySizes',
  industry: 'industries',
  leadSource: 'leadSources',
  leadSourceCommodity: 'leadSourceCommodities',
  fieldVersion: 'fieldVersions',
  fieldType: 'fieldTypes',
  field: 'fields',
  partner: 'partners',
  streetType: 'streetTypes',
  zipCode: 'zipCodes',
  roleCategory: 'roleCategories',
  role: 'roles',
  roleGroup: 'roleGroups',
  module: 'modules',
  reminder: "reminders",
  interaction: "interactions",
  interactionStep: "interactionSteps",
  meeting: "meetings",
  meetingOffer: 'meetingOffers',
  implementer: "implementers",
  implement: "implements",
  priority: "priorities",
  status: "statuses",
  statusStep: "statusSteps",
  billableCategory: "billableCategories",
  service: "services",
  product: "products",
  process: "processes",
  step: "steps",
  mandatory: "mandatories",
  projectType: "projectTypes",
  project: "projects",
  projectStep: "projectSteps",
  projectStepInteraction: "projectStepInteractions",
  contractType: "contractTypes",
  contract: "contracts",
  offer: "offers",
  fieldSetting: "fieldSettings",
  requiredField: "requiredFields",
  requiredRelation: "requiredRelations",
  model: "models",
  relativeFormat: "relativeFormats",
  billableImplement: "billableImplements",
  statusInitiator: "statusInitiators",
  statusInitiatorRule: "statusInitiatorRules",
  task: "tasks",
  taskPriority: "tasks/priorities",
  taskStatus: "tasks/statuses",
  taskTemplate: "tasks/templates",
  taskType: "tasks/types",
  decisionMaker: "decisionMakers",
  deadlineWarning: 'deadlineWarnings',
  microsoftAccessToken: 'microsoft/accessTokens', // Microsoft Graph api access token
  googleAccessToken: 'google/accessTokens', // Google api access token,
  vat: "vats",
  currency: "currencies",
  price: "prices",
  billableOffer: "billableOffers",
  billableContract: "billableContracts",
  billable: "billables",
  invoice: "invoices",
  invoiceType: "invoiceTypes",
  unit: "units",
  item: "items",
  requiredRepetition: "requiredRepetitions",
  campaign: "campaigns",
  billableImplementImplementer: "billableImplementImplementers",
  performance: 'performances',
  quantityTargetValue: 'targetValues',
  financialTargetValue: 'targetValues',
  customTargetValue: 'customTargetValues',
  gender: 'organizations/genders',
  career: 'organizations/careers',
  careerStage: 'organizations/careerStages',
  department: 'organizations/departments',
  division: 'organizations/divisions',
  team: 'organizations/teams',
  experience: 'organizations/experiences',
  hierarchyLevel: 'organizations/hierarchyLevels',
  hierarchicalChain: 'organizations/hierarchicalChains',
  introduction: 'organizations/introductions',
  knowledgeLevel: 'organizations/knowledgeLevels',
  location: 'organizations/locations',
  orgPosition: 'organizations/positions',
  specialization: 'organizations/specializations',
  skillLevel: 'organizations/skillLevels',
  skillScale: 'organizations/skillScales',
  skillType: 'organizations/skillTypes',
  skill: 'organizations/skills',
  positionSuperior: 'organizations/positionSuperiors',
  contributor: 'organizations/contributors',
  document: 'documents'
})

const sortableModules = sortable({
  field: 'fields',
  step: 'steps',
  currency: 'currencies',
  vat: 'vats',
  interaction: 'interactions',
  experience: 'organizations/experiences',
})

const searchableModules = searchable({
  implementer: {
    endpoint: 'users/implementers'
  },
  orgPosition: 'organizations/positions',
  location: 'organizations/locations',
  skill: {
    endpoint: 'organizations/skills',
    additional: {types: []}
  },
})

const autocompleteModules = autocomplete({
  partner: 'partners',
})

const accessibleModules = accessible({
  contract: 'contracts',
  meeting: 'meetings',
  offer: 'offers',
  invoice: 'invoices',
  implement: 'implements',
});

const merged = _.merge(crudModules, sortableModules, searchableModules, autocompleteModules, accessibleModules, modules)

// eslint-disable-next-line no-unused-vars
const store = createStore({
  modules: merged,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

store.reset = function () {
  Object.keys(this._actions).forEach(action => {
    if (action.endsWith('clearModule')) {
      this.dispatch(action)
    }
  }, this)
};

store.dispatch('notification/subscribe')
store.dispatch('notification/fetch')

export default store;

