import service from '@/api/project';
import {loading, handleError} from '@/state/generators/helpers';

export const state = {
    ownLoading: false,
    ownPager: {},
    ownAdditional: {},
    accessibleLoading: false,
    accessiblePager: {},
    accessibleAdditional: {},
    own: [],
    accessible: [],
    service: service('projects'),
    stepErrors: [],
    statuses: [],
    historyLoading: false,
    history: [],
    balanceLoading: false,
    balance: {},
}

export const getters = {
    ownLoading: function (state) {
        return !!state.ownLoading
    },
    ownPager: function (state) {
        return JSON.parse(JSON.stringify(state.ownPager))
    },
    own: function (state) {
        return state.own
    },
    accessibleLoading: function (state) {
        return !!state.accessibleLoading
    },
    accessiblePager: function (state) {
        return JSON.parse(JSON.stringify(state.accessiblePager))
    },
    accessible: function (state) {
        return state.accessible
    },
    stepErrors: function (state) {
        return JSON.parse(JSON.stringify(state.stepErrors))
    },
    statuses: (state) => state.statuses,
    historyLoading: function (state) {
        return !!state.historyLoading;
    },
    history: function (state) {
        return JSON.parse(JSON.stringify(state.history))
    },
    balanceLoading: function (state) {
        return !!state.balanceLoading;
    },
    balance: function (state) {
        return JSON.parse(JSON.stringify(state.balance))
    }
}

export const mutations = {
    'SET_OWN_LOADING': function (state, boolean = true) {
        state.ownLoading = boolean
    },

    'SET_OWN': function (state, list) {
        state.own = list
    },

    'SET_OWN_PAGER': function (state, pager = {}) {
        state.ownPager = pager
    },

    'SET_OWN_ADDITIONAL': function (state, data = {}) {
        state.ownAdditional = data
    },

    'REMOVE_FROM_OWN': function (state, item) {
        const index = state.own.findIndex(own => own.id === item.id)

        if (index !== -1) {
            state.own.splice(index, 1);
        }
    },

    'REMOVE_ITEM': function (state, id) {
        const index = state.list.findIndex(item => item.id === id);

        if (index !== -1) {
            state.list.splice(index, 1)
        }

        const allIndex = state.all.findIndex(item => item.id === id);

        if (allIndex !== -1) {
            state.all.splice(index, 1)
        }

        const ownIndex = state.own.findIndex(own => own.id === id)

        if (ownIndex !== -1) {
            state.own.splice(ownIndex, 1);
        }

        const accessibleIndex = state.accessible.findIndex(acc => acc.id === id)

        if (accessibleIndex !== -1) {
            state.accessible.splice(accessibleIndex, 1);
        }
    },

    'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
        state.accessibleLoading = boolean
    },

    'SET_ACCESSIBLE': function (state, list) {
        state.accessible = list
    },

    'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
        state.accessiblePager = pager
    },

    'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
        state.accessibleAdditional = data
    },

    'SET_STEP_ERRORS': function (state, errors = []) {
        state.stepErrors = errors
    },

    'UPDATE_ITEM': function (state, item) {
        Object.keys(item).forEach(prop => {
            state.item[prop] = item[prop]
        })
    },

    'SET_STATUSES': function (state, list = []) {
        state.statuses = list
    },

    'SET_HISTORY_LOADING': function (state, boolean = true) {
        state.historyLoading = boolean
    },

    'SET_HISTORY': function (state, list) {
        state.history = list
    },

    'SET_BALANCE_LOADING': function (state, boolean = true) {
        state.balanceLoading = boolean;
    },

    'SET_BALANCE': function (state, balance = {}) {
        state.balance = balance;
    },
}

export const actions = {
    own: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchOwn(filters).then(response => {
            commit('SET_OWN', response.data)
            commit('SET_OWN_PAGER', response.meta)
            commit('SET_OWN_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    accessible: function ({commit, state}, filters) {
        loading(commit)

        return state.service.fetchAccessible(filters).then(response => {
            commit('SET_ACCESSIBLE', response.data)
            commit('SET_ACCESSIBLE_PAGER', response.meta)
            commit('SET_ACCESSIBLE_ADDITIONAL', response.additional)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    step: function ({commit, state, getters}, {id, query}) {
        loading(commit)

        return state.service.step(id, query).then(response => {
            commit('SET_ITEM', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            loading(commit, false)
            commit('SET_STEP_ERRORS', e.response?.data?.errors || []);
            commit('app/ADD_ALERT', {
                message: getters.stepErrors?.join(' '),
                style: 'danger',
                icon: 'error',
                countdown: 8000
            }, { root: true })
        })
    },

    status: function ({commit, state, getters}, {id, query}) {
        loading(commit)

        return state.service.status(id, query).then(response => {
            commit('UPDATE_ITEM', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            loading(commit, false)
            commit('SET_STEP_ERRORS', e.response?.data?.errors || []);
            commit('app/ADD_ALERT', {
                message: getters.stepErrors?.join(' '),
                style: 'danger',
                icon: 'error',
                countdown: 3000
            }, { root: true })
        })
    },

    statuses: function ({commit, rootState}, payload) {
        loading(commit)

        return rootState.status.service.fetchAll(payload).then(response => {
            commit('SET_STATUSES', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    unlock: function ({commit, state}, {id, query}) {
        loading(commit)

        return state.service.unlock(id, query).then(response => {
            commit('SET_ITEM', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    close: function ({commit, state}, id) {
        loading(commit)

        return state.service.close(id).then(response => {
            commit('UPDATE_ITEM', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    transfer: function ({commit, state}, {id, data}) {
        loading(commit)

        return state.service.transfer(id, data).then(response => {
            commit('SET_ITEM', response.data)
            commit('REMOVE_FROM_OWN', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearStatuses: function ({commit}) {
        commit('SET_STATUSES', [])
    },

    history: function ({commit, state}, id) {
        loading(commit)

        return state.service.history(id).then(response => {
            commit('SET_HISTORY', response.data)
            loading(commit, false)
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearHistory: function ({ commit }) {
        commit('SET_HISTORY', []);
    },

    balance: function ({commit, state}, id) {
        commit('app/SET_LOADING', true, {root: true});
        commit('SET_BALANCE_LOADING', true);

        return state.service.balance(id).then(response => {
            commit('SET_BALANCE', response.data);
            commit('app/SET_LOADING', false, {root: true});
            commit('SET_BALANCE_LOADING', false);
            return response.data
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    clearBalance: function ({ commit }) {
        commit('SET_BALANCE', {});
    },
}
