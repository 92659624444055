import service from '@/api/contract';
import {loading, handleError} from '@/state/generators/helpers';
import {alert} from "../generators/helpers";

export const state = {
    service: service('contracts/own')
}

export const getters = {}

export const mutations = {}

export const actions = {
    sent: function ({commit, state}, id) {
        loading(commit)

        return state.service.sent(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    rejected: function ({commit, state}, id) {
        loading(commit)

        return state.service.rejected(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    canceled: function ({commit, state}, id) {
        loading(commit)

        return state.service.canceled(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    signed: function ({commit, state}, id) {
        loading(commit)

        return state.service.signed(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },

    closed: function ({commit, state}, id) {
        loading(commit)

        return state.service.closed(id).then(response => {
            const model = Object.assign({}, response.data)

            commit('REPLACE_ITEM', model);
            commit('SET_ITEM', model);
            alert(commit, this.$t('request.save_is_successful'))
            commit('SET_ERRORS', {});
            loading(commit, false)

            return model;
        }).catch((e) => {
            handleError(e, commit)
        })
    },
}
