import {loading, handleError} from './helpers'
import apiServiceGenerator from "@/api/accessibleGenerator";

export default function (endpoint) {
    return {
        state: {
            accessibleLoading: false,
            accessiblePager: {},
            accessibleAdditional: {},
            accessible: [],
            ownLoading: false,
            ownPager: {},
            ownAdditional: {},
            own: [],
            service: apiServiceGenerator(endpoint)
        },
        getters: {
            ownLoading: function (state) {
                return !!state.ownLoading
            },
            ownPager: function (state) {
                return JSON.parse(JSON.stringify(state.ownPager))
            },
            own: function (state) {
                return state.own
            },
            accessibleLoading: function (state) {
                return !!state.accessibleLoading
            },
            accessiblePager: function (state) {
                return JSON.parse(JSON.stringify(state.accessiblePager))
            },
            accessible: function (state) {
                return state.accessible
            },
        },
        mutations: {
            'SET_OWN_LOADING': function (state, boolean = true) {
                state.ownLoading = boolean
            },

            'SET_OWN': function (state, list) {
                state.own = list
            },

            'SET_OWN_PAGER': function (state, pager = {}) {
                state.ownPager = pager
            },

            'SET_OWN_ADDITIONAL': function (state, data = {}) {
                state.ownAdditional = data
            },

            'REMOVE_FROM_OWN': function (state, item) {
                const index = state.own.findIndex(own => own.id === item.id)

                if (index !== -1) {
                    state.own.splice(index, 1);
                }
            },

            'SET_ACCESSIBLE_LOADING': function (state, boolean = true) {
                state.accessibleLoading = boolean
            },

            'SET_ACCESSIBLE': function (state, list) {
                state.accessible = list
            },

            'SET_ACCESSIBLE_PAGER': function (state, pager = {}) {
                state.accessiblePager = pager
            },

            'SET_ACCESSIBLE_ADDITIONAL': function (state, data = {}) {
                state.accessibleAdditional = data
            },
        },
        actions: {
            own: function ({commit, state}, filters) {
                loading(commit)

                return state.service.fetchOwn(filters).then(response => {
                    commit('SET_OWN', response.data)
                    commit('SET_OWN_PAGER', response.meta)
                    commit('SET_OWN_ADDITIONAL', response.additional)
                    loading(commit, false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },

            accessible: function ({commit, state}, filters) {
                loading(commit)

                return state.service.fetchAccessible(filters).then(response => {
                    commit('SET_ACCESSIBLE', response.data)
                    commit('SET_ACCESSIBLE_PAGER', response.meta)
                    commit('SET_ACCESSIBLE_ADDITIONAL', response.additional)
                    loading(commit, false)
                    return response.data
                }).catch((e) => {
                    handleError(e, commit)
                })
            },
        }
    }
}