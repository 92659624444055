import qs from 'qs';
import axios from './axios'

export default function generateService(endpoint) {

    function fetchOwn(data) {
        return axios.get(`${endpoint}/own`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    function fetchAccessible(data) {
        return axios.get(`${endpoint}/accessible`, { params: data, paramsSerializer: params => qs.stringify(params, { encode: false })  }).then(response => {
            return response.data;
        }).catch(e => {
            throw e
        });
    }

    return {
        fetchAccessible,
        fetchOwn
    }
}
